@import "../../../index.module.scss";

.mobileNav {
  display: flex;
  flex-direction: column;
  // width: 100%;
  width: 20rem;
  max-width: 80%;
  min-height: 100vh;
  background-color: #e25e3e;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  padding-top: 0.1rem;
  gap: 2rem;
  z-index: 10;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  border-radius: 5px;
  height: 100%;

  @media (min-width: 768px) {
    display: none;
  }
}
.open {
  transform: translateX(0);
}
.logo {
  height: 3.5rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-align: right;
  padding-right: 0.5rem;

  & img {
    height: 100%;
  }
}
