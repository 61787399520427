@import "../../../../index.module";
.h2 {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}
.cartProduct {
  display: flex;
  flex-direction: column;
  min-height: auto;
  position: relative;

  &_top {
    display: flex;
    flex-direction: row;
    min-height: auto;
    gap: 0.5rem;
    align-items: center;

    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    &_content {
      &_price {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_new {
          font-size: 1.5rem;
          color: #e55807;
          margin: 0.5rem;
          font-weight: 700;
        }

        &_old {
          text-decoration: line-through;
          font-weight: 700;
          font-size: 1rem;
          color: $MediumGray;
          // margin-top: 1rem;
          margin-left: -5px;
        }

        &_discount {
          background-color: #4a2b13;
          padding: 0.5rem 0.5rem;
          color: white;
          font-weight: 700;
          font-size: 0.75rem;
          letter-spacing: 2px;
          margin-left: 1rem;
          border-radius: 10px;

          & p {
            min-width: max-content;
          }
        }
      }
    }
  }
  &_bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.3rem;

    &_container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.imgContainer {
  width: 100%;
  height: 12rem;
  min-width: 5rem;
  max-width: 15rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
  }
}
.quantity {
  font-size: 1.2rem;
  padding: 0 0.75rem;
  border: 2px solid $MediumGray;
}

.icon {
  border: 1px solid $MediumGray;
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  border-radius: 50%;
}

.btn {
  @include custom-button;
  margin-left: 0.5rem;
  width: 40%;
  font-size: 0.85rem;
  letter-spacing: 1px;
}

.back {
  &_feature {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.3rem;

    & p {
      font-family: "Noto Sans", sans-serif;
      font-size: 0.9rem;
      font-weight: 600;
    }

    &_container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      & label {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        font-weight: 600;
      }
      & input {
        position: relative;
        cursor: pointer;
        &[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          border: 2px solid #e55807;
        }

        &[type="radio"]:checked::before {
          content: "";
          display: block;
          width: 0.8rem;
          height: 0.8rem;
          background-color: #e55807;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.color {
  &_choose {
    & .wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_container {
      display: flex;
      flex-direction: column;
      margin-left: 0.2rem;

      & img {
        vertical-align: middle;
        padding: 0 2px;
        border: 2px solid #e3e3e3;
        border-radius: 10px;
        cursor: pointer;

        &.selected {
          border: 2px solid #ff5f49;
        }

        &:hover {
          border: 2px solid #ff5f49;
        }
      }

      & span {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        color: #3d2826;
      }
    }
  }

  &_btn {
    background-color: #e3e3e3;
    color: #e55807;
    display: flex;
    align-items: center;
    width: 5rem;
    border: 2px solid #e3e3e3;

    &:is(:hover, :focus, :active) {
      background-color: #e55807;
      color: #e3e3e3;
      border: 2px solid #e55807;
    }
  }

  &_more {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    background-color: #e3e3e3e6;
    border: 2px solid #e3e3e3e6;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 70%;
    border-radius: 10px;
    padding: 0.3rem;
    overflow: hidden;
    overflow-y: scroll;

    display: none;
    opacity: hidden;

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_visual {
      display: block;
      opacity: 1;
    }
  }
}

.btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & .btn {
    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
