@import "../../../index.module";

.error_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  & span {
    font-size: 1.5rem;
    margin-right: 0.3rem;
    color: #e14d2a;
  }
}

.productDetails {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 4rem 4rem 4rem;
  width: 95%;
  justify-content: space-evenly;
  text-align: left;

  @media (max-width: 1200px) {
    padding: 1rem 2rem 2rem 2rem;
    width: 98%;
  }
  @media (max-width: 1000px) {
    padding: 1rem 1rem 1rem 1rem;
    width: 100%;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;
    width: 100%;
  }

  &_h1 {
    font-size: 2.5em;
    font-weight: 400;
    // @media (max-width: 1000px) {
    //   font-size: 2.3rem;
    // }
  }

  &_h2 {
    font-size: 1.7em;
    font-weight: 700;
    text-align: left;
  }

  &_p {
    margin-top: 4px;
    padding-left: 3%;
    font-size: 1.5em;
    line-height: 1.4;
    color: $MediumGray;
  }

  &_image {
    height: 35rem;
    min-width: 30rem;
    text-align: center;
    position: sticky;
    top: 5rem;
    max-width: 50%;
    @media (max-width: 950px) {
      min-width: 25rem;
    }
    @media (max-width: 800px) {
      position: static;
      align-self: center;
      min-width: 100%;
    }

    & img {
      border: 2px solid rgb(230, 230, 230);
      padding: 10px;
      border-radius: 5%;
      width: 100%;
      height: 100%;
    }
  }

  &_container {
    padding: 0.5rem;
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
    position: relative;

    &_content {
      &_price {
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;

        &_new {
          font-size: 2em;
          color: #e55807;
          margin: 0 0.7em 0 0.5em;
          font-weight: 700;
        }

        &_old {
          text-decoration: line-through;
          font-weight: 700;
          font-size: 1.5em;
          color: $MediumGray;
          margin-left: -5px;
        }

        &_discount {
          background-color: #4a2b13;
          padding: 0.75em 1em;
          color: white;
          font-weight: 700;
          font-size: 1em;
          letter-spacing: 2px;
          margin-left: 1rem;
          border-radius: 10px;
        }
      }
    }
  }

  &_list {
    list-style: none;
    margin-left: 1em;

    &_listItem {
      margin: 0.5rem;
      &_p1 {
        &_heading {
          font-size: 1.4em;
          font-weight: 700;
        }

        &_description {
          font-size: 1.4em;
          font-weight: 600;
          color: $MediumGray;
        }
      }
    }
  }
}
.btn {
  width: 80%;
  font-size: 1rem;
  font-weight: 600;
  background-color: #e14d2a;
  color: white;
  border: 2px solid #e14d2a;
  transition: all 0.3s ease-in;
  margin-top: 1rem;

  &:is(:hover, :active) {
    background-color: white;
    color: #e14d2a;
  }
  @media (max-width: 800px) {
    width: 95%;
  }
}

.color {
  &_choose {
    & .wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_container {
      display: flex;
      flex-direction: column;
      margin-left: 0.2rem;

      & img {
        vertical-align: middle;
        padding: 0 2px;
        border: 2px solid #e3e3e3;
        border-radius: 10px;
        cursor: pointer;

        &.selected {
          border: 3px solid #ff5f49;
        }

        &:hover {
          border: 2px solid #ff5e49b4;
        }
      }

      & span {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        color: #3d2826;
      }
    }
  }

  &_btn {
    background-color: #e3e3e3;
    color: #e55807;
    display: flex;
    align-items: center;
    width: 5rem;
    border: 2px solid #e3e3e3;

    &:is(:hover, :focus, :active) {
      background-color: #e55807;
      color: #e3e3e3;
      border: 2px solid #e55807;
    }
  }

  &_more {
    position: absolute;
    z-index: 6;
    top: 35%;
    left: 70%;
    background-color: #e3e3e3e6;
    border: 2px solid #e3e3e3e6;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 15rem;
    border-radius: 10px;
    padding: 0.3rem;
    overflow: hidden;
    overflow-y: scroll;

    display: none;
    opacity: hidden;

    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 500px) {
      width: 65%;
      left: 55%;
      height: 12rem;
    }

    @media (max-width: 490px) {
      width: 75%;
      left: 55%;
      height: 12rem;
    }

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_visual {
      display: block;
      opacity: 1;
    }
  }
}

.back {
  &_feature {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.3rem;

    & h2 {
      // font-family: "Noto Sans", sans-serif;
      font-size: 1.6em;
      font-weight: 600;
    }

    &_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // gap: 5px;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      & label {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 1.4em;
        margin-top: 0.2rem;
        font-weight: 600;
      }
      & input {
        position: relative;
        cursor: pointer;
        &[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          border: 2px solid #e55807;
        }

        &[type="radio"]:checked::before {
          content: "";
          display: block;
          width: 0.5em;
          height: 0.5em;
          background-color: #e55807;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.icon {
  border: 1px solid $MediumGray;
  width: 1.75em;
  height: 1.75em;
  cursor: pointer;
  border-radius: 50%;
}

.quantity {
  font-size: 1.5em;
  padding: 0.25em 0.7em;
  border: 2px solid $MediumGray;
}

.productCard {
  &_bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.3rem;

    &_container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.quantity_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;

  & h2 {
    justify-self: flex-start;
    font-size: 1.8em;
  }
}

.Demo__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  & h2 {
    font-size: 1.5rem;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
}

.Demo__some_network {
  text-align: center;
}

.Demo__some_network__share_count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  font-size: 14px;
}

.Demo__some_network__share-button {
  cursor: pointer;
}

.Demo__some_network__share_button:hover:not(:active) {
  opacity: 0.75;
}
