@import "../../../../index.module";

.SectionCarouselCard_Container {
  width: 20em;
  // height: 20rem;
  position: relative;
  text-align: left;
  min-height: 20rem;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem;
  cursor: pointer;
  // outline: 1px solid #0000001a;
  // box-shadow: none;
  transition: all 0.3s ease-out;

  &:hover {
    transform: scale(1.1);
  }

  &_h2 {
    font-size: 1rem;
    font-weight: 200;
  }

  &_image {
    width: 100%;
    min-height: 8rem;
    height: 9rem;
    margin-bottom: 0.2rem;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  &_btn {
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    background-color: #e14d2a;
    color: white;
    border: 2px solid #e14d2a;
    transition: all 0.3s ease-in;

    &:is(:hover, :active) {
      background-color: white;
      color: #e14d2a;
    }
  }

  &_Price {
    width: 100%;

    &_new {
      font-size: 1rem;
      color: #e55807;
      margin: 0.5rem;
      font-weight: 900;
    }

    &_old {
      text-decoration: line-through;
      font-weight: 700;
      font-size: 0.8rem;
      color: $MediumGray;
    }
  }

  &_discount {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #4a2b13;
    padding: 0.2rem 0.5rem;
    color: white;
    font-weight: 700;
    font-size: 0.8rem;
    letter-spacing: 2px;
    // width: rem;
    // height: 2rem;
  }
}
