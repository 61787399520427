@import "../../../../_index.module";

.order {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}
.orderItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  align-items: center;
  justify-content: flex-start;
}
.orderCard {
  width: 90%;
  min-height: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  cursor: pointer;
  margin-top: 0.5rem;
}

.imgContainer {
  width: 10rem;
  height: 5rem;

  & img {
    width: 100%;
    height: 100%;
  }
}

.orderCard_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  & h2 {
    text-align: left;
    font-size: 1rem;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
}

h2 {
  text-align: center;
  font-size: 1rem;
  font-weight: 200;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    text-align: left;
  }
}

.status {
  font-weight: 600;
  color: #a2c579;
}

.PaymentDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  min-height: auto;
  width: 100%;
  background-color: #ffcf9950; //#ddf2fd;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.184);
  padding: 2rem 1.5rem 0.2rem 1.5rem;
  display: flex;
  flex-direction: column;

  & h2 {
    text-align: center;
    font-size: 1.5rem;
  }
}

.list {
  list-style: none;
  width: 100%;

  &_item {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_key {
      font-size: 1.5rem;
      font-weight: 200;
      color: $MediumGray;
    }

    &_value {
      font-size: 1.2rem;
      font-weight: 200;
    }
    &_total {
      border-bottom: none;
      padding: 0.4rem 0;
      border-top: 1px dashed $MediumGray;
      border-bottom: 1px dashed $MediumGray;

      &_key {
        font-size: 1.6rem;
        color: $AlmostBlack;
        font-weight: 700;
      }

      &_value {
        font-size: 1.3rem;
        color: $AlmostBlack;
        font-weight: 700;
      }
    }
  }
}

.date {
  align-self: center;
  font-size: 1.5rem;
  color: #3366cc;
  font-weight: 700;
}

.date_icon {
  font-size: 1.2rem;
  margin-right: 2px;
}
