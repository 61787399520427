@import "../../../../index.module";
.h2 {
  font-size: 1rem;
  font-weight: 600;
}
.p {
  font-size: 1rem;
  color: $MediumGray;
  font-weight: 700;

  & span {
    color: $LightRed;
  }
}
.orderItem {
  display: flex;
  flex-direction: row;
  min-height: auto;
  max-width: 20rem;
  gap: 1rem;
  padding: 0.5rem 1rem;

  @media (max-width: 768px) {
    max-width: max-content;
    margin: 0.5rem;
  }

  &_imgContainer {
    width: 9rem;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.oldPrice {
  margin-left: 2px;
  font-size: 0.8rem;
  color: #878787 !important;
  text-decoration: 1px line-through $MediumGray;
  // font-weight: 900;
}
