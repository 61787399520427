@import "../../../index.module";
.h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.h2 {
  text-align: center;
  font-size: 1.5rem;
}

.cart {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 2rem;

  @media (max-width: 1100px) {
    width: 90%;
    padding: 1.2rem;
  }

  @media (max-width: 920px) {
    width: 98%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding: 1rem;
  }

  &_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &_1 {
      min-height: auto;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &_2 {
      align-self: flex-start;
      min-height: auto;
      width: 100%;
      background-color: #ffcf9950; //#ddf2fd;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.184);
      padding: 2rem 1.5rem 0.2rem 1.5rem;
      display: flex;
      flex-direction: column;
    }
  }
}

.list {
  list-style: none;
  width: 100%;

  &_item {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_key {
      font-size: 1.5rem;
      font-weight: 200;
      color: $MediumGray;
    }

    &_value {
      font-size: 1.2rem;
      font-weight: 200;
    }
    &_total {
      border-bottom: none;
      padding: 0.4rem 0;
      border-top: 1px dashed $MediumGray;
      border-bottom: 1px dashed $MediumGray;

      &_key {
        font-size: 1.6rem;
        color: $AlmostBlack;
        font-weight: 700;
      }

      &_value {
        font-size: 1.3rem;
        color: $AlmostBlack;
        font-weight: 700;
      }
    }
  }
}

.content {
  width: 55%;
  position: sticky;
  top: 4rem;
  height: fit-content;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.checkout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &_desktop {
    @media (max-width: 768px) {
      display: none;
      opacity: 0;
    }
  }

  &_mobile {
    display: none;
    opacity: 0;

    @media (max-width: 768px) {
      display: flex;
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    position: sticky;
    bottom: 1px;
    background-color: #ebe4d1;
    padding: 0.5rem;
  }

  &_price {
    display: flex;
    flex-direction: column;
    gap: 2px;
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }

    &_subtotal {
      text-decoration: line-through;
      font-size: 1rem;
      color: $MediumGray;
    }

    &_total {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
.btn {
  @include custom-button;
  width: 55%;
}

.cartLoading {
  width: 100%;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
