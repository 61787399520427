@import "../../../index.module";

.h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.order {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 2rem;

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 920px) {
    width: 98%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding: 1rem;
  }
}

.noOrder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  min-height: auto;
  max-width: 20rem;
  margin: 0 auto;

  & h2 {
    font-size: 1.5rem;
  }
}

.btn {
  @include custom-button;
}

.paging {
  display: flex;
  flex-direction: row;
  max-width: 20rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  & span {
    border: 2px solid #e14d2a;
    padding: 0.2rem 0.5rem;
    font-size: 1.2rem;
    color: #e14d2a;
    margin: 0 0.5rem;
    font-weight: 700;
    border-radius: 5px;
    height: fit-content;
  }
}

.inActive {
  cursor: default;
  background-color: #e14c2a7b;
  border-color: #e14c2a7b;
  &:hover,
  &:active {
    background-color: #e14c2a7b;
    color: white;
  }
}
