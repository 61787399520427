@import "../../../index.module";
@import url("https://fonts.googleapis.com/css2?family=Gabriela&display=swap");

.footer {
  width: 100%;
  background-color: #e25e3e;
  margin-top: auto;

  &_container {
    width: 100%;

    &_rights {
      font-size: 0.65em;
      padding: 20px 0;
      text-align: center;
      color: rgba(255, 255, 255, 0.786);
      border-top: 2px solid white;

      & h1 {
        font-weight: 100;
        letter-spacing: 1px;
      }
    }

    &_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem 2.5rem 1rem 2.5rem;
      align-items: flex-start;
      gap: 2rem;
      text-align: left;

      &_listContent {
        padding-right: 2em;
        & h1 {
          color: white;
          font-size: 3em;
          font-family: "Gabriela", serif;
          margin: 0.5em 0;
        }

        & h2 {
          font-size: 3rem;
          color: white;
          font-weight: 200;
          text-align: left;
        }

        & p {
          font-size: 1.2rem;
          color: rgba(255, 255, 255, 0.786);
          margin: 5px 0;
          padding-left: 5px;
          // word-break: break-all;
          // max-width: 8rem;
          text-decoration: none;
        }

        &_list {
          list-style: none;
          text-align: left;

          &_listItem {
            margin: 5px 0;

            & a {
              padding-left: 5px;
              text-decoration: none;
              color: rgba(255, 255, 255, 0.786);
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}

a {
  list-style: none;
  text-decoration: none;
}

.socialMedia {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;

  & a {
    color: white;
    border-radius: 50%;
    padding: 0.4rem;
    cursor: pointer;

    & svg {
      width: 2.5em;
      height: 2.5em;
    }
  }

  &_fb {
    background-color: #286af9;
  }

  &_yt {
    background-color: #cd201f;
  }

  &_In {
    background-color: #25d366;
  }
}

.footer_ImageContainer {
  width: 17rem;
  height: 12rem;

  & img {
    width: 100%;
    height: 100%;
  }
}
