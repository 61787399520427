@import "../../../../index.module";
.btn {
  @include custom-button;
  margin-top: 1rem;
}
.btn_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
}

.addressCard {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: auto;
  text-align: left;
  align-items: flex-start;
  position: relative;

  &_address {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 85%;
  }
}

.h3 {
  font-size: 1.8rem;
  margin-left: 1rem;
  color: $MediumGray;
  font-weight: 200;
}

.p {
  font-size: 1.2rem;
}

.icon {
  color: $MediumGray;
  width: 1.8rem;
  height: 1.8rem;
}
