div.alice-carousel > ul.alice-carousel__dots {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.alice-carousel__dots-item {
  width: 0.8rem !important;
  height: 0.8rem !important;
}
.alice-carousel__stage-item {
  width: 100% !important;
}
div.alice-carousel {
  height: 80%;
  & div {
    height: 100%;
  }
}
