@import "../../../index.module.scss";

.card {
  background-color: $AlmostWhite;
  padding: 2rem 1.5rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 7px 30px rgba($color: #000000, $alpha: 0.34);
}

.card_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
