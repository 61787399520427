@import "../../index.module.scss";

.backDrop {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.hidden {
  display: none;
}
