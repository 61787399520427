@import "../../../index.module.scss";

.list {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0.5rem;
    align-items: flex-start;
    // align-items: center;
  }
}

.button {
  min-width: 5rem;
  font-size: 1rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  &_login {
    background-color: $AlmostBlack;
    color: $AlmostWhite;

    &:hover {
      background-color: $AlmostWhite;
      color: $AlmostBlack;
    }
  }
  &_signUp {
    background-color: $AlmostWhite;
    color: $AlmostBlack;

    &:hover {
      background-color: $AlmostBlack;
      color: $AlmostWhite;
    }
  }
}

.link {
  text-decoration: none;
  color: inherit;
  font-size: 1.5rem;
  color: $AlmostWhite;

  @media (max-width: 900px) {
    font-size: 1rem;
  }
}

.nav {
  width: 100%;
}

.margin_left_auto {
  margin-left: auto;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.dropDown {
  visibility: hidden;
  display: none;
  background-color: $AlmostWhite;
  // padding: 1rem;
  border-radius: 5px;
  min-width: 12rem;
  position: absolute;
  // top: 100%;
  z-index: 9999;
  right: 2.5rem;

  &:before {
    content: "";
    position: absolute;
    right: 15px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index: 9999;
  }

  @media (max-width: 768px) {
    position: relative;
    visibility: visible;
    display: block;
    background-color: transparent;
    right: 0;

    &:before {
      display: none;
    }
  }

  &:hover {
    visibility: visible;
    display: block;
  }
}

.dropItem {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  padding-bottom: 5px;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:hover + .dropDown {
    visibility: visible;
    display: block;
  }

  & span {
    font-size: 1.5rem;
    color: $AlmostWhite;
  }

  @media (max-width: 768px) {
    display: none;
  }
}
