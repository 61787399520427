@import "../../../index.module";
.productFilter {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;

  &_h1 {
    opacity: 0.23;
    font-size: 2.5rem;
    margin-bottom: -1.5rem;
    text-align: center;
  }

  &_h2 {
    font-size: 2rem;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-align: center;
  }

  &_content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &_item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      gap: 1rem;
    }
  }
}

.noProduct {
  font-size: 3rem;
  margin-top: 5rem;
  color: $LightRed;
}
