@import "../../../index.module";

.address {
  width: 85%;
  margin: 0 auto;

  @media (max-width: 920px) {
    width: 98%;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
  &_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (max-width: 400px) {
      padding: 0.4rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 30rem;
      align-items: center;

      @media (max-width: 768px) {
        max-width: none;
        width: 100%;
      }
    }

    &_content {
      width: 40rem;

      @media (max-width: 768px) {
        width: 100%;
      }
      &_card {
        background-color: #e5e5e5;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        min-height: auto;
        width: 100%;
        box-shadow: none;
        position: sticky;
        top: 5rem;
        @media (max-width: 768px) {
          position: static;
        }
      }
    }
  }
}

.form {
  text-align: left;
  width: 100%;
  padding: 1rem 1rem;
  &_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      & label {
        font-size: 1rem;
        color: #7d7c7c;
      }
      & span {
        font-size: 1.5rem;
        color: #b31312;
      }
      & input,
      textArea,
      select {
        width: 100%;
        padding: 0.5rem 1rem;
        border: 1px solid #7d7463;
        outline: none;
        border-radius: 10px;
        font-size: 1rem;
        color: #3876bf;
        font-weight: 600;
        &::placeholder {
          color: #7d7463;
        }
        &:is(:focus, :active) {
          border: 1px solid #99b080;
        }
      }
    }
  }
}

.h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.h2 {
  text-align: center;
  font-size: 1.5rem;
}

.btn {
  margin-top: 1.5rem;
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  align-self: center;
  text-align: center;
  border-radius: 8px;
  border: none;
  transition: all 0.3s;
  &:active {
    transform: scale(0.95) translateY(-2px);
  }
  @include custom-button;
}
