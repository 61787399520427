@import "../../../index.module.scss";

.header {
  display: flex;
  flex-direction: row;
  padding: 2rem 1.5rem;
  align-items: center;
  height: 3.5rem;
  width: 100%;
  background-color: #e25e3e;
  gap: 1.5rem;
  z-index: 4;
  transition: all 0.5s ease-out;
  position: fixed;

  &.transparent {
    background-color: #e25e3e;
    position: static;
  }
}

.logo {
  &_menu {
    & img {
      width: 2.5rem;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    display: none;
    @media (max-width: 768px) {
      display: inline;
      margin-left: auto;
    }
  }
  & img {
    width: 8rem;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
}

.hidden {
  @media (max-width: 768px) {
    display: none;
  }
}

.main {
  margin-bottom: 6rem;
}
