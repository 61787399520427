@import "../../../index.module.scss";

.card_login {
  z-index: 10;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translate(-50%, 0%);
  align-items: flex-start;
  width: 95%;
  max-width: 40rem;
  min-height: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 23rem;
    margin-top: 2rem;
  }
}

.profile_pic {
  max-width: 30rem;
  background-color: #f0efef;
  padding: 3rem 1.5rem;
  border-radius: 10px;
  text-align: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1.2rem 0 1.2rem 1.2rem;

  @media (max-width: 768px) {
    max-width: none;
    margin: 0;
    min-height: 20rem;
    padding: 1.5rem;
  }

  & h1 {
    font-size: 1.2rem;
    color: $AlmostBlack;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }
}
.for_signup {
  padding: 2rem 1.5rem;
}

.heading {
  text-align: center;
  margin-bottom: 1rem;
  color: $MediumGray;
  font-size: 1rem;
}

.login_form {
  width: 20rem;
}

.form_control {
  & label {
    width: 100%;
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.25rem;
    color: $MediumGray;
  }

  & input {
    background-color: transparent;
    width: 100%;
    height: 2.5rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid #b9b6d3;
    padding-left: 2rem;
    margin-bottom: 2rem;
    border-bottom-width: 4px;

    &::placeholder {
      font-weight: 600;
      color: #b9b6d3;
    }
  }
}

.btn {
  @include custom-button;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  &:active {
    transform: scale(0.95) translateY(-2px);
  }
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  align-self: center;
  text-align: center;
  border-radius: 8px;
  border: none;
  transition: all 0.3s;
  width: 100%;
  box-shadow: 7px 6px 28px 1px rgba($color: #000000, $alpha: 0.24);
  background-color: $AlmostWhite;
  font-size: 1rem;
  color: $AlmostBlack;
  margin-bottom: 0.5rem;

  &_email {
    background-color: #e14d2a;
    color: $AlmostWhite;
    margin-bottom: 1rem;
  }
  &:active {
    transform: scale(0.95) translateY(-2px);
  }

  &_google {
    width: 1.2rem;
    height: 1.2rem;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &_flute {
    width: 7rem;
    margin: 0 auto;

    @media (max-width: 768px) {
      margin: -1.2rem auto;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.par {
  font-size: 0.85rem;
  color: $MediumGray;
  margin-top: 0.2rem;
}

.account {
  & h2 {
    font-size: 1rem;
    margin-bottom: 0.3rem;
    color: $MediumGray;
  }

  &_btn {
    border-radius: 20px;
    border: 1px solid #e14d2a;
    background-color: $AlmostWhite;
    color: #e14d2a;
    font-weight: 200;
    cursor: pointer;
    transition: all 0.3s;
    &:is(:hover, :focus, :active) {
      background-color: #e14d2a;
      color: $AlmostWhite;
    }
  }
}

.heading2 {
  color: rebeccapurple;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
