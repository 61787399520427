@import "../../../_index.module";

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border-top: 1px solid #f0f0e4;
  border-bottom: 1px solid #f0f0e4;
  padding: 1rem 0 1rem 0;

  &_h1 {
    opacity: 0.23;
    font-size: 2.5rem;
    margin-bottom: -1.5rem;
  }

  &_h2 {
    margin-top: 10px;
    font-size: 2.5em;
    font-weight: 700;
    // text-shadow: 2px 2px 2px #989898;
    word-spacing: 5px;
    // letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
  }

  &_imageContainer {
    width: 8rem;
    height: 4rem;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &_carousel {
    padding: 1.25rem;
    position: relative;
    width: 100%;
  }

  &_item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 768px) {
      justify-content: space-evenly;
    }

    @media (max-width: 600px) {
      justify-content: center;
    }
  }
}
.btn_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  @include custom-button;
  display: inline;
  width: fit-content;
  justify-self: end;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}
