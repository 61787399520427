@import "../../../index.module.scss";

.error {
  width: 100%;
  text-align: center;

  & p {
    font-size: 1.5rem;
    color: $LightRed;
  }
}
