@import "../../../../index.module";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
.h2 {
  font-size: 1rem;
  font-weight: 600;
  word-wrap: break-word;
  text-align: left;
}
.productCard {
  display: flex;
  flex-direction: column;
  min-height: 20rem;
  max-height: 25rem;
  max-width: 20rem;
  perspective: 1000px;
  min-width: 20rem;

  &_top {
    display: flex;
    flex-direction: row;
    min-height: auto;
    max-width: 20rem;
    gap: 0.5rem;
    align-items: center;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    &_content {
      &_price {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_new {
          font-size: 1.5rem;
          color: #e55807;
          margin: 0.5rem;
          font-weight: 700;
        }

        &_old {
          text-decoration: line-through;
          font-weight: 700;
          font-size: 1rem;
          color: $MediumGray;
          // margin-top: 1rem;
          margin-left: -5px;
        }

        &_discount {
          background-color: #4a2b13;
          padding: 0.5rem 0.5rem;
          color: white;
          font-weight: 700;
          font-size: 0.75rem;
          letter-spacing: 2px;
          margin-left: auto;
          border-radius: 10px;
        }
      }
    }
  }
  &_bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.3rem;

    &_container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.imgContainer {
  width: 100%;
  height: 12rem;
  margin: -0.7rem auto 0.6rem auto;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
  }
}
.quantity {
  font-size: 1.2rem;
  padding: 0 0.75rem;
  border: 2px solid $MediumGray;
}

.icon {
  border: 1px solid $MediumGray;
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  border-radius: 50%;
}

.btn {
  @include custom-button;
  margin-left: 0.5rem;
  width: 40%;
  font-size: 0.75rem;

  &_goToCart {
    width: 100%;
  }
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.flipped {
    transform: rotateY(180deg);
  }
}

.front,
.back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
  top: -1.6rem;
  left: 0;
  z-index: 5;

  display: flex;
  flex-direction: column;
  min-height: 22rem;
  justify-content: space-around;

  &_title {
    font-size: 1.34rem;
    font-family: "Poppins", sans-serif;
    color: #fa7070;
    font-weight: 700;
  }

  &_feature {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    border-bottom: 2px dashed #fa7070;
    padding: 0.4rem 0;

    &_quantity {
      width: 8rem;

      & svg {
        width: 1.5rem;
        height: 1.5rem;
        border: 2px solid #e55807;
        color: #e55807;
      }

      & span {
        padding: 0 0.8rem;
        border: 2px solid #e55807;
        color: #e55807;
        font-family: "Noto Sans", sans-serif;
        font-weight: 600;
        font-size: 1rem;
      }
    }

    & p {
      font-family: "Noto Sans", sans-serif;
      font-size: 1.1rem;
      font-weight: 600;
    }

    &_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & label {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        font-weight: 600;
      }
      & input {
        position: relative;
        cursor: pointer;
        &[type="radio"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          border: 2px solid #e55807;
        }

        &[type="radio"]:checked::before {
          content: "";
          display: block;
          width: 0.8rem;
          height: 0.8rem;
          background-color: #e55807;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

.color {
  &_choose {
    & .wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_container {
      display: flex;
      flex-direction: column;
      margin-left: 0.2rem;

      & img {
        vertical-align: middle;
        padding: 0 2px;
        border: 2px solid #e3e3e3;
        border-radius: 10px;
        cursor: pointer;

        &.selected {
          border: 2px solid #ff5f49;
        }

        &:hover {
          border: 2px solid #ff5f49;
        }
      }

      & span {
        text-align: center;
        font-size: 0.75rem;
        font-weight: 600;
        color: #3d2826;
      }
    }
  }

  &_btn {
    background-color: #e3e3e3;
    color: #e55807;
    display: flex;
    align-items: center;
    width: 5rem;
    border: 2px solid #e3e3e3;

    &:is(:hover, :focus, :active) {
      background-color: #e55807;
      color: #e3e3e3;
      border: 2px solid #e55807;
    }
  }

  &_more {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    background-color: #e3e3e3e6;
    border: 2px solid #e3e3e3e6;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    border-radius: 10px;
    padding: 0.3rem;
    overflow: hidden;
    overflow-y: scroll;

    display: none;
    opacity: hidden;

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_visual {
      display: block;
      opacity: 1;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #fa7070;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fa7070;
}

.addToCart {
  display: flex;
  justify-content: center;
  margin-top: 8px;

  & .btn {
    width: 60%;
    font-size: 1rem;
  }
}

.turn {
  position: absolute;
  right: -10px;
  cursor: pointer;
  border: 1px solid #e55807;
  background-color: #e55807;
  border-radius: 10px;
  color: white;
  opacity: 0.5;
  top: 5px;

  &:is(:hover, :focus, :active) {
    opacity: 1;
  }
}
