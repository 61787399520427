@import "../../../index.module";
.h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

.h2 {
  text-align: center;
  font-size: 1.5rem;
}

.btn {
  margin-top: 1.5rem;
  @include custom-button;

  &_placeOrder {
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    position: sticky;
    bottom: 0;
  }
}

.checkout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 2rem;
  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 920px) {
    width: 98%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
    padding: 1rem;
  }
  &_container1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }

    &_deliveryCard {
      &_card {
        max-width: 30rem;

        @media (max-width: 768px) {
          max-width: none;
        }
      }
    }

    &_order {
      &_card {
        overflow-y: auto;
        padding: 0.5rem;
        max-height: 15rem;
      }
      & .h2 {
        margin-bottom: 0.5rem;
      }
    }
  }

  &_container2 {
    align-self: center;
    min-height: auto;
    width: 55%;
    background-color: #ffcf9950; //#ddf2fd;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.184);
    padding: 2rem 1.5rem 0.2rem 1.5rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.list {
  list-style: none;
  width: 100%;

  &_item {
    margin-bottom: 0.5rem;
    border-bottom: 2px solid $MediumGray;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_key {
      font-size: 1.5rem;
      font-weight: 200;
      color: $MediumGray;
    }

    &_value {
      font-size: 1.2rem;
      font-weight: 200;
    }
    &_total {
      border-bottom: none;
      padding-bottom: 0;

      &_key {
        font-size: 1.6rem;
        color: $AlmostBlack;
        font-weight: 700;
      }

      &_value {
        font-size: 1.3rem;
        color: $AlmostBlack;
        font-weight: 700;
      }
    }
  }
}
