@import "../../../../index.module";

.h2 {
  font-size: 2rem;
  color: #fa7070;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
}
.p {
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 0.2rem;
  font-weight: 700;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    font-size: 1.5rem;
    &::after {
      content: ":-";
    }
  }
}

.filter {
  min-height: max-content;
  flex-direction: column;
  gap: 1rem;
  min-width: 12rem;
  max-height: min-content;
  align-self: flex-start;
  //   padding: 0;
}

.form {
  width: 100%;
  &_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid #fa7070;
    padding-bottom: 0.3rem;

    @media (max-width: 768px) {
      flex-direction: unset;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: center;
    }

    & label {
      font-size: 1.2rem;
    }
  }
}

.btn {
  @include custom-button;
}

input[type="radio"] {
  transform: scale(1.2);
}

.isFilter {
  width: 100%;
  text-align: center;

  & span {
    margin: 0.5rem;
    padding: 0.2rem 0.4rem;
    background-color: #fa7070;
    font-size: 1.2rem;
  }
}
