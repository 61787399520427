$AlmostWhite: hsl(0, 0%, 98%);
$MediumGray: hsl(0, 0%, 41%);
$AlmostBlack: hsl(0, 0%, 8%);
$Blue: hsl(246, 80%, 60%);
$LightOrange: hsl(15, 100%, 70%);
$SoftBlue: hsl(195, 74%, 62%);
$LightRed: hsl(348, 100%, 68%);
$LimeGreen: hsl(145, 58%, 55%);
$Violet: hsl(264, 64%, 52%);
$SoftOrange: hsl(43, 84%, 65%);
$VeryDarkBlue: hsl(226, 43%, 10%);
$DarkBlue: hsl(235, 46%, 20%);
$UnsaturatedBlue: hsl(235, 45%, 61%);
$PaleBlue: hsl(236, 100%, 87%);
$DenaturedBlue: hsl(235, 45%, 61%);
$PaleBlue: hsl(236, 100%, 87%);

@import "react-alice-carousel/lib/scss/alice-carousel.scss";

@mixin custom-button {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  background-color: #e14d2a;
  color: white;
  border: 2px solid #e14d2a;
  transition: all 0.3s ease-in;

  &:hover,
  &:active {
    background-color: white;
    color: #e14d2a;
  }
}
