.btn {
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  align-self: center;
  text-align: center;
  border-radius: 8px;
  border: none;
  transition: all 0.3s;
  min-height: 2.5rem;
  &:active {
    transform: scale(0.95) translateY(-2px);
  }
}
