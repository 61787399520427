.toastify {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  text-transform: uppercase;
}

.ToastContainer {
  font-size: 1rem;
  text-transform: uppercase;
}
